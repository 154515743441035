/*---------------------
    Tab Styles  
-----------------------*/

.rn-default-tab {
  // Tab Button
  .tab-button {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    gap: 20px;
    @media #{$sm-layout} {
      gap: 10px;
    }
    .react-tabs__tab {
      padding: 20px 44px;
      margin: 0 20px;

      @media #{$lg-layout} {
        padding: 20px 24px;
      }

      @media #{$md-layout} {
        padding: 10px 14px;
      }

      @media #{$sm-layout} {
        padding: 5px 10px;
      }

      &.react-tabs__tab--selected {
        background: var(--color-lessdark);
      }
    }
    .rn-tab-button {
      button {
        padding: 0;
        margin: 0;
        background: transparent;
        border: 0 none;
        color: var(--color-white);
      }
    }
  }

  // Tab Content
  .rn-tab-content {
    background: var(--color-lessdark);
    padding: 40px 40px;
    @media #{$sm-layout} {
      padding: 30px;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.style-two {
    .tab-button {
      margin-bottom: 30px;
      .react-tabs__tab {
        border-radius: var(--radius-small);
        border-width: 2px;
        border-style: solid;
        border-image-slice: 1;
        border-color: var(--color-secondary);
      }
    }
    .rn-tab-content {
      border-radius: var(--radius);
    }
  }
}
