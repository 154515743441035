/*-----------------------------
    Portfolio details  
--------------------------------*/
.rwt-portfolio-details {
  .content-left {
    .title {
      font-size: 38px;
      margin-bottom: 33px;
      text-align: center;
      @media #{$sm-layout} {
        font-size: 25px;
        margin-bottom: 20px;
      }
    }
    .single-list-wrapper {
      margin: -5px;
      .single-list {
        margin: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        & + .single-list {
          border-top: 1px solid var(--color-border);
        }
        label {
          font-size: 20px;
          color: #fff;
          display: inline-block;
          padding-right: 16px;
          margin: 0;
        }
        span {
          font-size: 18px;
        }
      }
    }
  }
  .content-right {
    .subtitle {
      font-size: 25px;
      letter-spacing: 0.5px;
      font-weight: 500;
    }
    .description {
      p {
        margin-bottom: 10px;
        text-align: justify;
      }
    }
  }
}

.rwt-portfolio-details {
  .alignwide {
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    width: auto;
    @media #{$lg-layout} {
      margin-left: auto;
      margin-right: auto;
    }
    @media #{$md-layout} {
      margin-left: auto;
      margin-right: auto;
    }
    @media #{$sm-layout} {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.portfolioBanner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.portfolioTechnology,
.portfolioClientDetail {
  background-color: var(--color-dark-purple-bg);
  border-radius: var(--radius);
  padding: 40px 40px;
}

.portfolioClientDetail {
  padding: 20px;
}

.portfolioClientDetail,
.portfolioClientDetail div h4,
.single-list label {
  color: white !important;
}

.single-list span {
  text-align: right;
}

.portfolioTechContent {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 60px;
}

.portfolioTechItem {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 50px;
  text-align: center;
  gap: 15px;
  align-items: center;
}

.portfolioTechItem img {
  height: 30%;
}

.portfolioTechItem p {
  color: white !important;
  font-size: var(--font-size-b3);
}

.portfolioTechnologyTitle {
  font-size: 20px;
  color: #181818;
  display: inline-block;
  padding-right: 16px;
  margin: 20px 0;
}
