/*---------------------
    Technologies Styles  
-----------------------*/

.technologyContent {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  @media #{$sm-layout} {
    flex-wrap: wrap;
    gap: 30px 0px;
  }
}
.technologyItem {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 150px;
  text-align: center;
  gap: 15px;
  align-items: center;
}

.technologyItem img {
  height: 50%;
}

.technologyItem p {
  color: white !important;
}
